import React, { useState, useEffect } from 'react'
import JunoClientActionModal from '../../../../components/table/JunoClientActionModal'
import { useDispatch, useSelector } from 'react-redux'
import { apiService } from '../../../../../../common/apiCallService'
import {
    getCurrencySymbol,
    clearAllValuesInObj,
    validateAmount,
    getClientTransactionUrl,
    useJunoCommonFunctions,
} from '../../../../../helpers'
import AttachmentsUpload from '../../../../components/form/AttachmentsUpload'
import SavedAccountsList from './SavedAccountsList'
import FieldsForEdit from './FieldsForEdit'
import {
    setErrorMessage,
    setSuccessMessage,
} from '../../../../../../Redux/actions/junoAction'
import JncCurrenciesTypeahead from '../../../../components/global/JncCurrenciesTypeahead'
import JncButton from '../../../../components/global/JncButton'
import WithdrawConfirm from '../../../../components/form/WithdrawalConfirm'

export default function BalanceFiatWithdraw({
    onClose,
    open,
    row,
    accountDetails,
}) {
    const {
        getBalances,
        getSavedAccounts,
        saveAccountApi,
        updateAccountApi,
        getTransactions,
        sendNotification,
    } = useJunoCommonFunctions()
    const savedAccounts = useSelector((state) => state.juno.savedAccounts)
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const api_url = useSelector((state) => state.config.api_url)
    const clientData = useSelector((state) => state.juno.clientData)
    const clientTransactionFee = useSelector(
        (state) => state.juno.clientTransactionFee,
    )
    const balancesData = useSelector((state) => state.juno.balancesCurrencies)
    const dispatch = useDispatch()
    const [isConfirm, setIsConfirm] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [isFailed, setIsFailed] = useState(false)
    const [amountError, setAmountError] = useState('')
    const [attachments, setAttachments] = useState([])
    const [attachmentsError, setAttachmentsError] = useState('')
    const [attachmentUrls, setAttachmentUrls] = useState([])
    const [failedErr, setFailedErr] = useState('')
    //--- for accounts
    const [isNewAccountComponent, setIsNewAccountComponent] = useState(
        savedAccounts && savedAccounts.length < 1,
    )
    const [isEditAccount, setIsEditAccount] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [isChoosenAccount, setIsChoosenAccount] = useState(false)
    const [transactionIdFore2e, setTransactionIdFore2e] = useState('')
    //---
    const [selectedCurrency, setSelectedCurrency] = useState([])
    const [errorInputNames, setErrorsInputNames] = useState([])

    const [formData, setFormData] = useState({
        beneficiaryName: '',
        beneficiaryAddress: '',
        bankName: '',
        bankAddress: '',
        beneficiaryAccountNumber: '',
        sortCode: '',
        swift: '',
        iban: '',
        additionalBankDetails: '',
        amount: '',
        reference: '',
        accountNickName: '',
        saveBankAccount: false,
    })
    const clientWithdrawalFee = clientTransactionFee.find(
        (e) => e.transactionType === 'Withdrawal',
    )

    useEffect(() => {
        if (row) {
            setSelectedCurrency(
                row
                    ? [
                        row.transactionDetails
                            ? row.currency
                            : row.currencyShortName,
                    ]
                    : [],
            )
        }
        if (accountDetails) {
            setIsChoosenAccount(true)
            setIsNewAccountComponent(true)
            setFormData(accountDetails)
            setSelectedCurrency(
                accountDetails.currency ? [accountDetails.currency] : [],
            )
        }
    }, [row, open, accountDetails])

    useEffect(() => {
        setIsNewAccountComponent(savedAccounts && savedAccounts.length < 1)
    }, [savedAccounts])

    function onChangeInput(e) {
        const name = e.target.name
        let value
        if (e.target.type === 'checkbox') value = e.target.checked
        else value = e.target.value
        setFormData((x) => {
            return {
                ...x,
                [name]: value,
            }
        })
        if (name == 'amount') {
            setAmountError(
                validateAmount(
                    value,
                    clientWithdrawalFee,
                    selectedCurrency,
                    balancesData,
                ),
            )
        }
        if (
            name == 'accountNickName' &&
            value &&
            errorInputNames.includes('accountNickName')
        ) {
            setErrorsInputNames((prev) =>
                prev.filter((x) => x !== 'accountNickName'),
            )
        }
    }

    useEffect(() => {
        if(selectedCurrency.length > 0 && formData.amount) {
            setAmountError(
                validateAmount(
                    formData.amount,
                    clientWithdrawalFee,
                    selectedCurrency,
                    balancesData,
                ),
            )
        }
    }, [selectedCurrency])

    function openIsNewAccountComponent() {
        setErrorsInputNames([])
        setIsNewAccountComponent(true)
        setFormData(clearAllValuesInObj(formData))
        setSelectedCurrency(
            row.currencyShortName || row.currency
                ? [row.currencyShortName || row.currency]
                : [],
        )
    }

    function closeIsNewAccountComponent() {
        setIsNewAccountComponent(false)
        setErrorsInputNames([])
        setAmountError('')
    }

    function returnIsChoosenAccount() {
        setIsChoosenAccount(false)
        setIsNewAccountComponent(false)
        setErrorsInputNames([])
        setAmountError('')
    }

    function handleIsEditAccount(id) {
        setIsNewAccountComponent(false)
        const account = savedAccounts && savedAccounts.find((x) => x._id == id)
        setFormData((x) => {
            return {
                ...account,
            }
        })
        setIsEditAccount(true)
    }

    function hideIsEditAccount() {
        setIsEditAccount(false)
        returnIsChoosenAccount()
        setFormData(clearAllValuesInObj(formData))
    }

    function chooseAccount(id) {
        const account = savedAccounts && savedAccounts.find((x) => x._id == id)
        setSelectedCurrency(
            row.currencyShortName || row.currency
                ? [row.currencyShortName || row.currency]
                : account.currency
                    ? [account.currency]
                    : [],
        )
        setFormData((x) => {
            return {
                ...account,
            }
        })
        setIsChoosenAccount(true)
        setIsNewAccountComponent(true)
    }

    function validateFields() {
        let updatedErrors = []
        if ((formData.saveBankAccount && !formData.accountNickName) || (!formData.accountNickName && isEditAccount)) {
            updatedErrors = [...updatedErrors, 'accountNickName']
        }
        if (!selectedCurrency[0]) {
            updatedErrors = [...updatedErrors, 'currency']
        }
        if (validateAmount(formData.amount, clientWithdrawalFee, selectedCurrency, balancesData) !== '') {
            updatedErrors = [...updatedErrors, 'amount']
            setAmountError(validateAmount(formData.amount, clientWithdrawalFee, selectedCurrency, balancesData))
        }
        setErrorsInputNames(updatedErrors)
        return updatedErrors
    }

    async function submit() {
        setIsLoading(true)
        try {
            if (attachments.length > 0) {
                await Promise.all(
                    attachments.map(async (data, index) => {
                        const transactionImage = `transactionImage${Math.floor(100000 + Math.random() * 900000)}`
                        await getSignedUrl(data, transactionImage, index)
                    }),
                )
            } else {
                createTransactionCall()
            }

        } catch (err) {
            setIsLoading(false)
            setIsFailed(true)
        }
    }

    function onCloseHandler() {
        setIsChoosenAccount(false)
        setIsDelete(false)
        setIsConfirm(false)
        resetUseStates()
        onClose()
    }

    function onSuccess() {
        setIsLoading(false)
        onCloseHandler()
        dispatch(setSuccessMessage('Withdraw has been completed successfully'))
    }

    function reset() {
        setIsLoading(false)
        resetUseStates()
    }

    function resetUseStates() {
        setIsNewAccountComponent(savedAccounts && savedAccounts.length < 1)
        setSelectedCurrency([])
        setIsEditAccount(false)
        setIsFailed(false)
        setAmountError('')
        setAttachments([])
        setAttachmentsError('')
        setFormData(clearAllValuesInObj(formData))
        setFailedErr('')
        setErrorsInputNames([])
        setAttachmentUrls([])
    }

    function onSuccessDelete() {
        if (savedAccounts && savedAccounts.length == 1) {
            setIsNewAccountComponent(true)
        }
        getSavedAccounts()
        setFormData(clearAllValuesInObj(formData))
        setIsEditAccount(false)
        setIsDelete(false)
    }

    function handleFile(files) {
        setAttachmentsError('')
        for (let i = 0; i < files.length; i++) {
            if (files[i].size <= 5 * 1024 * 1024) {
                setAttachments((x) => [...x, files[i]])
            } else {
                setAttachmentsError('File size exceeds the limit (5 MB)')
                setTimeout(() => {
                    setAttachmentsError('')
                }, 2000)
            }
        }
    }

    function handleDelete(e, i) {
        setAttachments(attachments.filter((item, index) => index !== i))
        setAttachmentsError('')
    }

    const handleChange = function (e) {
        e.preventDefault()
        if (e.target.files && e.target.files[0]) {
            handleFile(e.target.files)
        }
    }

    const handleToCurrency = (val) => {
        setSelectedCurrency(val)
        if (val.length > 0) {
            setErrorsInputNames((prev) => prev.filter((x) => x !== 'currency'))
        }
    }

    useEffect(() => {
        if (
            attachments.length > 0 &&
            attachments.length === attachmentUrls.length
        ) {
            createTransactionCall()
        }
    }, [attachmentUrls])

    const getSignedUrl = async (file, transactionImage, filecount) => {
        const payload = {
            imgcount: `file${filecount}`,
            imageType: 'clientPayout',
            transactionImage: transactionImage ? transactionImage : null,
        }
        const index = parseInt(filecount)
        try {
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/get-client-presigned-url',
                {
                    ...payload,
                },
                async (resp) => {
                    if (resp) {
                        await fetch(resp.data[0], {
                            method: 'PUT',
                            body: file,
                            headers: {
                                'Content-Type': 'application/*',
                                'cache-control': 'public, max-age=0',
                            },
                        })
                            .then(async (res) => {
                                const imgUrl = `${imageBaseUrl}/client-payout/file${index}/${transactionImage}/documents`
                                setAttachmentUrls((x) => [...x, imgUrl])
                            })
                            .catch((err) => {
                                setIsLoading(false)
                                setAttachmentsError(
                                    'Something went wrong with uploaded files',
                                )
                                console.log('err: ', err)
                                setAttachmentUrls([])
                            })
                    }
                },
            )
        } catch (err) {
            console.log(err)
            setAttachmentsError('Something went wrong with uploaded files')
            setIsLoading(false)
        }
    }

    function createTransactionCall() {
        const transactionEmail = localStorage.getItem('user_name')
        const data = {
            beneficiaryName: formData.beneficiaryName,
            beneficiaryAddress: formData.beneficiaryAddress,
            bankName: formData.bankName,
            bankAddress: formData.bankAddress,
            beneficiaryAccountNumber: formData.beneficiaryAccountNumber,
            sortCode: formData.sortCode,
            swift: formData.swift,
            iban: formData.iban,
            additionalBankDetails: formData.additionalBankDetails,
            amount: formData.amount,
            reference: formData.reference,
            currency: selectedCurrency[0],
        }
        apiService(
            getClientTransactionUrl(api_url),
            {
                currencyType: 'fiat',
                type: 'Withdrawal',
                transactionDetails:
                    attachmentUrls.length > 0
                        ? { ...data, attachment: attachmentUrls }
                        : data,
                client: clientData.clientId,
                currency: selectedCurrency[0],
                transactionEmail,
                balance: {
                    balanceAmount: formData.amount,
                },
            },
            async (data) => {
                if (data) {           
                    sendNotification(data?.transactionId,data?.paymentProvider)             
                    setTransactionIdFore2e(data.transactionId)
                    onSuccess()
                    setIsConfirm(false)
                    if (formData.saveBankAccount) {
                        saveAccountApi(
                            { ...formData, currency: selectedCurrency },
                            onSaveAccountSuccess,
                            onSaveAccountError,
                            setFailedErr,
                        )
                    }
                    getBalances()
                    getTransactions()
                }
            },
            (err) => {
                setIsLoading(false)
                setIsFailed(true)
                setIsConfirm(false)
                setFailedErr(err)
                console.log(err)
                dispatch(setErrorMessage(err))
            },
        )
    }

    function onSaveAccountSuccess() {
        getSavedAccounts()
    }

    const setIsConfirmHandler = () => {
        const error = validateFields()
        if (error.length === 0) {
            setIsConfirm(true)
        }
    }

    function onSaveAccountError() {
        setIsLoading(false)
        setIsFailed(true)
    }

    function DialogContentComponent() {
        return (
            <div>
                {isConfirm && (
                    <WithdrawConfirm
                        declineConfirm={declineConfirm}
                        submit={submit}
                        formData={formData}
                        selectedCurrency={selectedCurrency}
                        isLoading={isLoading}
                        attachments={attachments}
                    />
                )}
                {row &&
                    !isConfirm &&
                    savedAccounts && savedAccounts.length > 0 &&
                    !isEditAccount &&
                    !isChoosenAccount && (
                        <div className="savedAccounts">
                            <div className="savedAccounts-tabs">
                                <button
                                    className={`${!isNewAccountComponent && 'active'}`}
                                    onClick={closeIsNewAccountComponent}
                                    data-e2e="saved-accounts"
                                >
                                    Saved Accounts
                                </button>
                                <button
                                    className={`${isNewAccountComponent && 'active'}`}
                                    onClick={openIsNewAccountComponent}
                                    data-e2e="new-account"
                                >
                                    New Account
                                </button>
                            </div>
                        </div>
                    )}
                {row &&
                    !isConfirm &&
                    !isNewAccountComponent &&
                    !isEditAccount &&
                    !isChoosenAccount && (
                        <SavedAccountsList
                            chooseAccount={chooseAccount}
                            setIsAccountEdit={handleIsEditAccount}
                        />
                    )}
                {row && !isConfirm && isEditAccount && (
                    <FieldsForEdit
                        isEditAccount={isEditAccount}
                        formData={formData}
                        onChangeInput={onChangeInput}
                        errorInputNames={errorInputNames}
                    />
                )}
                {row &&
                    !isConfirm &&
                    isNewAccountComponent &&
                    !isEditAccount && (
                        <div>
                            <JncCurrenciesTypeahead
                                selected={selectedCurrency && selectedCurrency[0] ? selectedCurrency : []}
                                onChange={handleToCurrency}
                                hasError={errorInputNames.includes('currency')}
                            />

                            {!isChoosenAccount && (
                                <FieldsForEdit
                                    errorInputNames={errorInputNames}
                                    formData={formData}
                                    onChangeInput={onChangeInput}
                                />
                            )}
                            <div className="jncModal__field mt">
                                <p className="jncModal__label">
                                    Amount <span className="required">*</span>
                                </p>
                                <div
                                    className={`jn-client-prefix-input ${amountError ? 'has-error' : ''}`}
                                >
                                    <span>
                                        {getCurrencySymbol(
                                            selectedCurrency[0],
                                        ) || '?'}
                                    </span>
                                    <input
                                        data-e2e="amount-box"
                                        required
                                        onChange={(e) => onChangeInput(e)}
                                        value={formData.amount || ''}
                                        name="amount"
                                        className="theme-modal-field-input"
                                        type="text"
                                        placeholder="Enter Amount"
                                    />
                                </div>
                                {amountError && (
                                    <div
                                        className="jncAlert alert alert-danger"
                                        role="alert"
                                        data-e2e="error"
                                        data-e2e-msg={amountError}
                                    >
                                        {amountError}
                                    </div>
                                )}
                            </div>
                            <div className="jncModal__field mt">
                                <p className="jncModal__label">Reference</p>
                                <input
                                    data-e2e="reference-textbox"
                                    onChange={(e) => onChangeInput(e)}
                                    value={formData.reference}
                                    name="reference"
                                    className="jncInput"
                                    type="text"
                                    placeholder="Enter Reference (optional)"
                                />
                            </div>
                            <AttachmentsUpload
                                handleFile={handleFile}
                                attachmentsError={attachmentsError}
                                attachments={attachments}
                                handleDelete={handleDelete}
                                handleChange={handleChange}
                            />
                        </div>
                    )}
            </div>
        )
    }

    function DialogActionsComponent() {
        return (
            <div className="w-100">
                {row &&
                    !isConfirm &&
                    isNewAccountComponent &&
                    !isEditAccount &&
                    !isChoosenAccount && (
                        <div
                            className={`jncModal__btns justify-end ${savedAccounts.length > 0 ? 'grid-on-mobile' : ''}`}
                        >
                            {savedAccounts.length > 0 && (
                                <JncButton
                                    disabled={isLoading}
                                    onClickCall={closeIsNewAccountComponent}
                                    text="Return"
                                    isOutlinedStyle={true}
                                />
                            )}
                            <JncButton
                                loading={isLoading}
                                disabled={amountError}
                                onClickCall={setIsConfirmHandler}
                                dataE2e="submit-btn"
                                text="Continue"
                            />
                        </div>
                    )}
                {isChoosenAccount && !isConfirm && (
                    <div className="grid-on-mobile jncModal__btns justify-end">
                        <JncButton
                            disabled={isLoading}
                            text="Return"
                            isOutlinedStyle={true}
                            onClickCall={returnIsChoosenAccount}
                        />
                        <JncButton
                            loading={isLoading}
                            disabled={amountError}
                            onClickCall={setIsConfirmHandler}
                            dataE2e="submit"
                            text="Continue"
                        />
                    </div>
                )}
                {row && !isConfirm && isEditAccount && (
                    <div className="savedAccounts-btns-right">
                        <div className="min-516">
                            <JncButton
                                disabled={isLoading}
                                onClickCall={hideIsEditAccount}
                                text="Cancel"
                                isOutlinedStyle={true}
                            />
                        </div>
                        <JncButton
                            loading={isLoading}
                            onClickCall={updateAccount}
                            text="Save"
                        />
                    </div>
                )}
                {row &&
                    !isConfirm &&
                    !isNewAccountComponent &&
                    !isEditAccount && (
                        <div className="jncModal__btns justify-end">
                            <JncButton
                                onClickCall={onCloseHandler}
                                text="Close"
                                isOutlinedStyle={true}
                            />
                        </div>
                    )}
                {isConfirm && (
                    <div className="jncModal__btns grid-on-mobile justify-end no-border">
                        <JncButton
                            disabled={isLoading}
                            text="Cancel"
                            isOutlinedStyle={true}
                            onClickCall={declineConfirm}
                        />
                        <JncButton
                            text="Confirm"
                            loading={isLoading}
                            onClickCall={submit}
                            dataE2e="confirm-btn"
                        />
                    </div>
                )}
            </div>
        )
    }

    function onUpdateAccountSuccess() {
        setIsLoading(false)
        getSavedAccounts()
        hideIsEditAccount(true)
    }

    function onUpdateAccountError() {
        setIsLoading(false)
    }

    const declineConfirm = () => {
        setIsConfirm(false)
    }

    function updateAccount() {
        validateFields()
        if (!formData.accountNickName) return
        setIsLoading(true)
        updateAccountApi(
            { ...formData, currency: selectedCurrency },
            onUpdateAccountSuccess,
            onUpdateAccountError,
            setFailedErr,
        )
    }

    const backToAccount = () => setIsConfirm(false)

    return (
        <div>
            <JunoClientActionModal
                onClose={onCloseHandler}
                open={open}
                titles={{
                    start: 'Withdrawal',
                    edit: 'Edit Accounts',
                    confirm: 'Withdrawal Confirmation',
                }}
                backTo={{ start: hideIsEditAccount, account: backToAccount }}
                states={{
                    start: !isNewAccountComponent && !isEditAccount,
                    edit: isEditAccount && !isConfirm,
                    chosen:
                        isChoosenAccount && isNewAccountComponent && !isConfirm,
                    new:
                        isNewAccountComponent &&
                        !isEditAccount &&
                        !isChoosenAccount &&
                        !isConfirm,
                    confirm: isConfirm,
                }}
                isLoading={isLoading}
                isFailed={isFailed}
                reset={reset}
                failedErr={failedErr}
                formData={formData}
                isDelete={isDelete}
                setIsDelete={setIsDelete}
                onSuccessDelete={onSuccessDelete}
                transactionIdFore2e={transactionIdFore2e}
                dialogContent={DialogContentComponent()}
                dialogActions={DialogActionsComponent()}
            ></JunoClientActionModal>
        </div>
    )
}
